import * as React from "react";
import {graphql, Link, navigate} from "gatsby";
import {useEffect, useRef, useState} from "react";
import htmlFromField from '../lib/htmlFromField'
import {Helmet} from "react-helmet";
import Navigation from "../components/Navigation";
import {gsap, Power1} from "gsap";
import { Textfit } from 'react-textfit';
import { getBackgroundColorFromName } from "../components/Helpers/Color";
import Logo from "../components/Logo";
import {getPageTitle} from "../components/Helpers/Meta";
import Fade from "../transitions/Fade";
import TransitionLink  from 'gatsby-plugin-transition-link'
import {off, on} from "../components/Events";
import {getID} from "../components/Helpers/Random";
import ScrollIndicator from "../components/ScrollIndicator";



export const TEAM_DETAIL_QUERY = graphql`
  query TeamDetailQuery($id: String!) {
    content: contentfulTeamMember(
      id: { eq: $id }
    ) {
      firstname
      lastname
      slug
      function
      vanityTitle
      backgroundColor
      textLeft {
        childMarkdownRemark {
          html
        }
      }
      textRight {
        childMarkdownRemark {
          html
        }
      }
      profilePicture {
        gatsbyImageData(width: 400, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        title
      }
      personalPhoto {
          file {
            contentType
            url
          }
        gatsbyImageData(width: 1920, placeholder: BLURRED, formats: [AUTO, WEBP], quality: 100)
        title
      }
    }
  }
`;

const TeamDetailTemplate = ({location, data}) => {
    const {
        content: {
            firstname,
            lastname,
            profilePicture,
            textLeft,
            textRight,
            personalPhoto,
            vanityTitle,
            'function': jobTitle,
            backgroundColor
        }
    } = data;

    const isWindowDefined = typeof window !== 'undefined';
    const [windowSize, _setWindowSize] = useState(isWindowDefined
        ? {width: window.innerWidth, height: window.innerHeight}
        : {width: 0, height: 0});

    const imageRef = useRef(null);
    const personalPhotoRef = useRef(null);
    const windowSizeRef = useRef(windowSize);
    const bodyRef = useRef(null);
    const contentRef = useRef(null);

    const setWindowSize = (data) => {
        windowSizeRef.current = data;
        _setWindowSize(data);
    }

    const animatePhoto = () => {

        const photoWidth = 300;
        if (typeof location.state === 'undefined'
            || location.state === null
            || typeof location.state.fromTeamOverview === 'undefined'
            || typeof location.state.teamPhoto === 'undefined') {

            const imageRect = imageRef.current.getBoundingClientRect();

            gsap.killTweensOf(imageRef.current);
            gsap.timeline()
                .set(imageRef.current, {
                    opacity: 0,
                    x: windowSizeRef.current.width / 2 - imageRect.width / 2,
                    y: windowSizeRef.current.height / 2 - imageRect.height / 2,
                    // transform: 'translateX(0) translateY(15vh)',
                    // width: teamPhoto.width,
                    // height: teamPhoto.height,
                    // scale: 1
                    transform: 'translateX(0) translateY(10vh)',
                    width: photoWidth,
                    height: photoWidth * (imageRect.height / imageRect.width)
                })
                .to(imageRef.current, {
                    duration: 0.6,
                    ease: Power1.easeOut,
                    opacity: 1,
                    transform: 'translateX(0) translateY(10vh)',
                    width: photoWidth,
                    height: photoWidth * (imageRect.height / imageRect.width)
                }, 0.6);

        } else {
            const teamPhoto = location.state.teamPhoto;
            gsap.killTweensOf(imageRef.current);
            gsap.timeline()
                .set(imageRef.current, {
                    opacity: 1,
                    x: windowSizeRef.current.width / 2 - teamPhoto.width / 2,
                    y: windowSizeRef.current.height / 2 - teamPhoto.height / 2,
                    // transform: 'translateX(0) translateY(15vh)',
                    width: teamPhoto.width,
                    height: teamPhoto.height,
                    scale: 1
                })
                .to(imageRef.current, {
                    duration: 0.6,
                    ease: Power1.easeOut,
                    transform: 'translateX(0) translateY(10vh)',
                    width: photoWidth,
                    height: photoWidth * (teamPhoto.height / teamPhoto.width)
                }, 0.6);
        }

    }

    useEffect(() => {
        const calculateWindowSize = () => setWindowSize({width: window.innerWidth, height: window.innerHeight});
        calculateWindowSize();

        window.addEventListener('resize', calculateWindowSize);

        animatePhoto();
        gsap.timeline()
            .set(contentRef.current, {
                opacity: 0
            })
            .to(contentRef.current, {
                opacity: 1,
                duration: 1,
                ease: Power1.easeOut,
            }, 0.6);

        gsap.timeline()
            .set(personalPhotoRef.current, {
                opacity: 0
            })
            .to(personalPhotoRef.current, {
                duration: 1,
                ease: Power1.easeOut,
                opacity: 0.5
            }, 0.6);

        // On component unmount
        return () => {
            window.removeEventListener('resize', calculateWindowSize);
        }
    }, [])

    const hidePage = () => {
        Fade.animate([
            { target: '.o-section', type: Fade.TYPE_OPACITY },
            { target: '.c-logo', type: Fade.TYPE_OPACITY },
            { target: '.c-breadcrumbs', type: Fade.TYPE_OPACITY },
        ])
    };

    useEffect(() => {
        on('page:out', hidePage)

        return () => {
            off('page:out', hidePage)
        }
    }, [])


    const isGif = personalPhoto.file.contentType === "image/gif";
    const retrievedPersonalPhoto = isGif
        ? personalPhoto.file.url
        : personalPhoto.gatsbyImageData.images.fallback.src


    const [isLoaded, setIsLoaded] = useState(false);
    useEffect(() => {setTimeout(() => {setIsLoaded(true)}, 400)}, []);

    const [counter, setCounter] = useState(0);

    useEffect(() => {
        setCounter(counter+1)
    }, [])

    return (
        <>
            <Logo />
            <Navigation invert={backgroundColor.toLowerCase() === 'blauw'} />
            <Helmet>
                <title>{getPageTitle({title: `${firstname} ${lastname}`})}</title>
                <style>
                    {`body { background-color: ${getBackgroundColorFromName(backgroundColor)}; }`}
                </style>
            </Helmet>
            <ScrollIndicator />

            <div className={`o-section c-team-detail--section ${backgroundColor.toLowerCase() === 'blauw' ? 'e-blue' : ''} ${isLoaded ? 'e-active--delay' : ''}`}>
                <div className={"o-layout o-layout--full-page@desk c-team-detail--hero"}>
                    <img src={profilePicture?.gatsbyImageData?.images.fallback.src}
                         ref={imageRef}
                         className={"c-team-detail--photo u-visually-hidden@until-desk"} />

                     <img src={retrievedPersonalPhoto}
                          className={"c-team-detail--personal-photo u-visually-hidden@until-desk e-effect e-fade"}
                          ref={personalPhotoRef}
                          />

                    <div className={"o-layout o-layout--full-page@desk u-columns-24 c-team-detail--content o-two-column-text u-typo--content"}
                    ref={contentRef}>
                        <div className={"u-colspan-24 u-visually-hidden@desk c-team-detail--mob--info"}>
                            <div className={"o-layout u-columns-24"}>
                                <div className={"u-colspan-12"}>
                                    <div className={"c-breadcrumb"}>{firstname} {lastname}</div>
                                </div>
                                <div className={"u-colspan-12 c-team-detail--mob--photo"}>
                                    <img src={profilePicture?.gatsbyImageData?.images.fallback.src} />
                                </div>
                            </div>
                        </div>
                        <div className={"u-colspan-24 o-layout u-columns-24"}>
                            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"} />
                            <div className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall"}>
                                <div className={"o-layout o-layout--gap-large"}>
                                    {/*Breadcrumb*/}
                                    <div className={"u-colspan-12 c-breadcrumb-link"}><TransitionLink
                                        to={"/team"}
                                        className={"u-font-family--secondary e-effect e-fade e-move--up-gentle"}
                                        entry={{delay: Fade.anim_delay}}
                                        exit={{
                                            length: Fade.anim_length,
                                            trigger: (details) => Fade(details)}}>&lt;- Alle Weekenders</TransitionLink></div>

                                    <div className={"u-colspan-12 u-visually-hidden@desk"}>
                                        <div className={"o-layout"}>
                                            <div className={"u-colspan-12 c-team-detail--mob--vanity-title e-effect e-fade e-move--up-gentle"}>{vanityTitle}</div>
                                            <div className={"u-colspan-12 c-team-detail--mob--job-title e-effect e-fade e-move--up-gentle"}>&amp; {jobTitle}</div>
                                        </div>
                                    </div>

                                    <div className={"u-colspan-12 u-colspan-6@desk e-effect e-fade e-move--up-gentle"} dangerouslySetInnerHTML={{
                                        __html: htmlFromField(textLeft)
                                    }} />
                                    <div className={"u-colspan-12 u-colspan-6@desk e-effect e-fade e-move--up-gentle"} dangerouslySetInnerHTML={{
                                        __html: htmlFromField(textRight)
                                    }} />
                                </div>
                            </div>
                            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"} />

                            {/*Vanity title*/}
                            <div className={"u-colspan-24 c-team-detail--title-container  u-visually-hidden@until-desk e-effect e-fade"} >
                                    <Textfit className={"c-team-detail--vanity-title"}
                                             key={`tf-${getID()}-${counter}`}
                                        mode="single"
                                        forceSingleModeWidth={false}
                                        max={200}>
                                        {vanityTitle}
                                    </Textfit>
                            </div>

                            {/*Job description*/}
                            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"} />
                            <div className={"u-colspan-20 u-colspan-16@desk u-colspan-12@wall  e-effect e-fade e-move--up-gentle"}>
                                <Textfit className={"c-team-detail--job-title u-visually-hidden@until-desk"}
                                         key={`tf-${getID()}-${counter}`}
                                         mode="single"
                                         forceSingleModeWidth={false}
                                         max={200}>
                                    &amp; {jobTitle}
                                </Textfit>
                            </div>
                            <div className={"u-colspan-2 u-colspan-4@desk u-colspan-6@wall"} />
                        </div>
                    </div>
                </div>
            </div>
            <div className={"u-mob--bottom-margin__default u-visually-hidden@desk"} />
        </>
    )
};

export default TeamDetailTemplate;
